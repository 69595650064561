import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { CoreModule } from './app/core/core.module'
import { environment } from './environments/environment'

import * as Sentry from '@sentry/angular'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: environment.sentry.tracingOrigins,
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(CoreModule)
  .catch(err => console.error(err))
