import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { Toast, ToastType } from './toast.model'

@Injectable({
  providedIn: 'root',
})
export class ToastsService {
  constructor() {}

  private toastsSource = new Subject<Toast>()
  toasts$ = this.toastsSource.asObservable()

  show(toastObject: Toast) {
    this.toastsSource.next(toastObject)
  }

  /*
  Helper Functions
  */
  info(message: string, payload?: any) {
    this.show({ message, payload, type: ToastType.INFO })
  }
  success(message: string, payload?: any) {
    this.show({ message, payload, type: ToastType.SUCCESS })
  }
  warning(message: string, payload?: any) {
    this.show({ message, payload, type: ToastType.WARNING })
  }
  error(message: string, payload?: any) {
    this.show({ message, payload, type: ToastType.ERROR })
  }
}
