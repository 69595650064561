import { Component, Inject, LOCALE_ID } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFireFunctions } from '@angular/fire/functions'

@Component({
  selector: 'app-sign-in',
  templateUrl: 'sign-in.component.html',
  styleUrls: ['sign-in.component.scss'],
})
export class SignInComponent {
  loading = false

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public fireauth: AngularFireAuth,
    private fns: AngularFireFunctions,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.route.queryParams.subscribe(async params => {
      if (params.code) {
        try {
          this.loading = true
          const callable = this.fns.httpsCallable('api/auth/twitch-token')
          const token = await callable(params).toPromise()
          await this.fireauth.signInWithCustomToken(token)
          const redirect = window.sessionStorage.getItem('redirect')
          if (redirect) {
            window.sessionStorage.removeItem('redirect')
            window.location.href = redirect
          } else {
            this.router.navigate([`/${this.locale}`])
          }
        } catch (error) {
          console.error(error)
          alert('Error!')
        } finally {
          this.loading = false
        }
      }
    })
  }

  async login() {
    this.loading = true
    window.location.href = `${environment.apiBaseURL}/auth/twitch-redirect?locale=${this.locale}`
  }
}
