<div class="toast-container">
  <div
    *ngFor="let toast of toasts; let index = index"
    class="toast show"
    [ngClass]="getClassByType(toast.type)"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="toast-header">
      <strong class="me-auto">{{ getTitleByType(toast.type) }}</strong>
      <button
        type="button"
        class="btn-close btn-close-white"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
    <div class="toast-body">
      {{ toast.message }}
      <pre *ngIf="toast.payload" [innerHtml]="getPayload(toast.payload)"></pre>
    </div>
  </div>
</div>
