import { Component } from '@angular/core'
import { Toast, ToastType } from './toast.model'
import { ToastsService } from './toasts.service'

@Component({
  selector: 'app-toasts',
  templateUrl: 'toasts.component.html',
  styleUrls: ['toasts.component.scss'],
})
export class ToastsComponent {
  public toasts: Toast[] = []

  constructor(private toastsService: ToastsService) {
    this.toastsService.toasts$.subscribe((toast: Toast) => {
      this.toasts.push(toast)
      const autoCloseTime = 5000
      setTimeout(() => {
        this.close(toast)
      }, autoCloseTime)
    })
  }

  getClassByType(toastType: ToastType) {
    switch (toastType) {
      case ToastType.SUCCESS:
        return 'bg-success'
      case ToastType.ERROR:
        return 'bg-danger'
      case ToastType.INFO:
        return 'bg-info'
      case ToastType.WARNING:
        return 'bg-warning'
      default:
        return 'bg-info'
    }
  }

  getTitleByType(toastType: ToastType) {
    switch (toastType) {
      case ToastType.SUCCESS:
        return 'Success!'
      case ToastType.ERROR:
        return 'Error!'
      case ToastType.INFO:
        return 'Info:'
      case ToastType.WARNING:
        return 'Warning!'
      default:
        return 'Info:'
    }
  }

  getPayload(payload: any) {
    try {
      if (typeof payload === 'object') {
        return JSON.stringify(payload, null, '  ')
      }
      return payload
    } catch (error) {
      return null
    }
  }

  close(toast: Toast) {
    this.toasts.splice(this.toasts.indexOf(toast), 1)
  }
}
