import { Component, Inject, Input, LOCALE_ID } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { TwitchUser } from 'src/app/models/twitch-user.model'
import { AuthService } from 'src/app/services/auth.service'
import { environment } from 'src/environments/environment'
import { SidebarService } from '../sidebar/sidebar.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public loggingIn = false

  constructor(
    public fireAuth: AngularFireAuth,
    public auth: AuthService,
    @Inject(LOCALE_ID) private locale: string,
    public sidebarService: SidebarService
  ) {}

  get currentLocale() {
    return this.locale
  }

  setLocale(locale: string) {
    document.cookie = `firebase-language-override=${locale}`
    window.location.href = window.location.origin
  }

  login() {
    this.loggingIn = true
    window.location.href = `${environment.apiBaseURL}/auth/twitch-redirect`
  }
}
