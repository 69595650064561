import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore } from '@angular/fire/firestore'
import { Router } from '@angular/router'
import { BehaviorSubject, Subscription } from 'rxjs'
import { User } from '../models/user.model'

@Injectable({ providedIn: 'root' })
export class AuthService {
  userUid?: string
  user?: User
  isLoggedIn = false

  user$ = new BehaviorSubject<User | undefined>(undefined)
  fireauthUserSubscription!: Subscription

  constructor(
    public readonly fireauth: AngularFireAuth,
    private firestore: AngularFirestore,
    private router: Router
  ) {
    fireauth.user.subscribe((user: any) => {
      if (user) {
        const userDocument = this.firestore.doc<any>(`users/${user?.uid}`)
        const user$ = userDocument.valueChanges()
        this.fireauthUserSubscription = user$.subscribe((data: any) => {
          if (data) {
            this.user = data
            this.user$.next(data)
          } else {
            this.signOut()
          }
        })
        this.userUid = user.uid
        this.isLoggedIn = true
      } else {
        this.userUid = undefined
        this.user = undefined
        this.user$.next(undefined)
        this.isLoggedIn = false
      }
    })
  }

  updateUserTokens(tokens: { token: string; refreshToken: string }) {
    if (this.user) {
      this.user.token = tokens.token
      this.user.refreshToken = tokens.refreshToken
      this.user$.next(this.user)
    }
  }

  signOut() {
    if (this.fireauthUserSubscription) {
      this.fireauthUserSubscription.unsubscribe()
    }
    this.router.navigate(['/sign-in'])
    this.fireauth.signOut()
  }
}
