import { Component } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'
import { SidebarService } from './sidebar.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent {
  sidebarCompact = false

  constructor(public auth: AuthService, public sidebarService: SidebarService) {
    this.sidebarCompact = this.sidebarService.sidebarCompact
    this.sidebarService.sidebarCompact$.subscribe(compact => {
      this.sidebarCompact = compact
    })
  }

  get userChannel() {
    return this.auth.user?.twitchUser.login
  }

  logout() {
    this.auth.signOut()
  }
}
