import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { AngularFireModule } from '@angular/fire'
import { AngularFirestoreModule } from '@angular/fire/firestore'
import {
  AngularFireAnalyticsModule,
  UserTrackingService,
} from '@angular/fire/analytics'
import { environment } from 'src/environments/environment'
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth'
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore'
import {
  AngularFireFunctionsModule,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/functions'
import { AppComponentsModule } from '../components/app-components.module'

import { HeaderComponent } from './header/header.component'
import { RouterModule } from '@angular/router'

const analyticsImports = environment.production
  ? [AngularFireAnalyticsModule]
  : []
@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    RouterModule,
    AppComponentsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    ...analyticsImports,
  ],
  providers: [
    UserTrackingService,
    {
      provide: USE_AUTH_EMULATOR,
      useValue: !environment.production ? ['localhost', 9099] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: !environment.production ? ['localhost', 8080] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: !environment.production ? ['localhost', 5001] : undefined,
    },
  ],
  declarations: [HeaderComponent],
  exports: [
    AppComponentsModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    HeaderComponent,
  ],
})
export class SharedModule {}
