import { Directive, ElementRef, Input, OnChanges } from '@angular/core'

declare var bootstrap: any

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnChanges {
  @Input() appTooltip: string | null = ''
  @Input() appTooltipPlacement = 'auto'

  constructor(private el: ElementRef) {}

  ngOnChanges() {
    const tooltip = new bootstrap.Tooltip(this.el.nativeElement, {
      boundary: document.body,
      container: this.el.nativeElement,
      placement: this.appTooltipPlacement,
      title: this.appTooltip,
      html: true,
    })
  }
}
