<ng-container *ngIf="auth.user$ | async; else loading">
  <main class="layout" [class.sidebar-compact]="sidebarCompact" data-bot="main">
    <app-sidebar></app-sidebar>
    <div class="body">
      <app-header></app-header>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
</ng-container>
<ng-template #loading>
  <main class="loader">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </main>
</ng-template>
