<main class="bg-dark text-white">
  <div class="left-menu">
    <button
      class="btn"
      (click)="sidebarService.toggleSidebarCompact()"
      data-bot="sidebar-togggle"
    >
      <i class="bi bi-list"></i>
    </button>
  </div>

  <div class="center-menu">
    <a class="logo" routerLink="/">
      <img src="assets/images/logo-site-inline-light.svg" height="28" />
    </a>
  </div>

  <div class="right-menu">
    <ul class="navbar-nav me-3 i18n-selection">
      <li class="nav-item dropdown">
        <a
          class="nav-link text-light dropdown-toggle"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            [src]="'assets/icons/i18n/' + currentLocale + '.svg'"
            class="flag mini"
            [alt]="currentLocale"
          />
        </a>
        <ul class="dropdown-menu dropdown-menu-dark">
          <li>
            <a class="dropdown-item" (click)="setLocale('en')">
              <img src="assets/icons/i18n/en.svg" class="flag" alt="English" />
            </a>
          </li>
          <li>
            <a class="dropdown-item" (click)="setLocale('pt')">
              <img
                src="assets/icons/i18n/pt.svg"
                class="flag"
                alt="Português"
              />
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <div class="user" *ngIf="auth.user$ | async as user; else signin">
      <img
        [src]="user.twitchUser.profileImageUrl"
        class="rounded"
        [appTooltip]="user.twitchUser.displayName"
      />
    </div>
    <ng-template #signin>
      <button class="btn btn-secondary btn-sm" (click)="login()">
        <span
          *ngIf="loggingIn"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span *ngIf="!loggingIn">Login</span>
      </button>
    </ng-template>
  </div>
</main>
