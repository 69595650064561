import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private sidebarCompactSource = new Subject<boolean>()
  sidebarCompact$ = this.sidebarCompactSource.asObservable()
  sidebarCompact = !!JSON.parse(
    localStorage.getItem('sidebarCompact') ?? 'false'
  )

  toggleSidebarCompact() {
    this.sidebarCompact = !this.sidebarCompact
    localStorage.setItem('sidebarCompact', JSON.stringify(this.sidebarCompact))
    this.sidebarCompactSource.next(this.sidebarCompact)
  }
}
