<main>
  <div class="logo">
    <img src="assets/images/logo-square-light.svg" height="150" />
    <h2 i18n class="engage">
      Engage your viewers with cool giveaways and live games!
    </h2>
  </div>
  <button class="btn btn-secondary btn-lg" (click)="login()" data-bot="login">
    <span
      *ngIf="loading"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    <span *ngIf="!loading">
      <svg
        width="20"
        height="40"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="twitch-logo"
      >
        <path
          d="M3.54167 0L0 3.57143V16.4286H4.25V20L7.79167 16.4286H10.625L17 10V0H3.54167ZM15.5833 9.28571L12.75 12.1429H9.91667L7.4375 14.6429V12.1429H4.25V1.42857H15.5833V9.28571Z"
          fill="#9146ff"
        />
        <path
          d="M13.4584 3.92847H12.0417V8.21418H13.4584V3.92847Z"
          fill="#9146ff"
        />
        <path
          d="M9.56242 3.92847H8.14575V8.21418H9.56242V3.92847Z"
          fill="#9146ff"
        />
      </svg>

      <span i18n>Login with Twitch</span></span
    >
  </button>
</main>
