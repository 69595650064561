import { Component } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'
import { SidebarService } from '../sidebar/sidebar.service'

@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss'],
})
export class LayoutComponent {
  sidebarCompact = false

  constructor(public auth: AuthService, public sidebarService: SidebarService) {
    this.sidebarCompact = this.sidebarService.sidebarCompact
    this.sidebarService.sidebarCompact$.subscribe(compact => {
      this.sidebarCompact = compact
    })
  }
}
