import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http'
import { catchError, retry, switchMap, take, tap } from 'rxjs/operators'
import { Observable, Subject, throwError } from 'rxjs'
import { AuthService } from '../auth.service'
import { environment } from 'src/environments/environment'
import { AngularFireFunctions } from '@angular/fire/functions'
import { AngularFireAuth } from '@angular/fire/auth'

@Injectable({ providedIn: 'root' })
export class ApiTwitchInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false

  tokenRefreshedSource = new Subject()
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable()

  constructor(
    private auth: AuthService,
    private fns: AngularFireFunctions,
    public fireAuth: AngularFireAuth
  ) {
    console.log('ApiTwitchInterceptor constructor...')
  }

  addAuthHeader(request: HttpRequest<any>, token?: string) {
    request = request.clone({
      headers: request.headers.set(
        'Authorization',
        `Bearer ${token ?? this.auth.user?.token}`
      ),
    })
    request = request.clone({
      headers: request.headers.set('Client-Id', environment.twitch.clientId),
    })
    return request
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next()
          observer.complete()
        })
      })
    } else {
      this.refreshTokenInProgress = true

      const callable = this.fns.httpsCallable('api/auth/twitch-refresh-token')
      return callable({
        userId: this.auth.user?.twitchUser.id,
        refreshToken: this.auth.user?.refreshToken,
      }).pipe(
        tap((data: any) => {
          this.refreshTokenInProgress = false
          this.auth.updateUserTokens(data)
          this.tokenRefreshedSource.next(null)
        }),
        catchError(error => {
          this.refreshTokenInProgress = false
          this.auth.signOut()
          return throwError(error)
        })
      )
    }
  }

  handleResponseError(error: any, request?: any, next?: any): any {
    if (error.status === 401) {
      return this.refreshToken().pipe(
        switchMap(result => {
          request = this.addAuthHeader(request, result.token)
          return next.handle(request)
        }),
        catchError(e => {
          if (e.status !== 401) {
            return this.handleResponseError(e)
          } else {
            this.auth.signOut()
            return throwError(error)
          }
        })
      )
    }

    return throwError(error)
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Handle request
    request = this.addAuthHeader(request)

    // Handle response
    return next.handle(request).pipe(
      catchError(error => {
        return this.handleResponseError(error, request, next)
      })
    )
  }
}
