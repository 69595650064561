import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard'
import { Routes } from '@angular/router'
import { LayoutComponent } from './core/layout/layout.component'
import { SignInComponent } from './core/sign-in/sign-in.component'

const authenticatedUsers = () => redirectUnauthorizedTo(['/sign-in'])

export const appRoutes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: 'overlays',
    loadChildren: () =>
      import('src/app/modules/overlays/overlays.module').then(
        m => m.OverlaysModule
      ),
  },
  {
    path: 'tools',
    component: LayoutComponent,
    loadChildren: () =>
      import('src/app/modules/tools/tools.module').then(m => m.ToolsModule),
    ...canActivate(authenticatedUsers),
  },
  {
    path: 'games',
    component: LayoutComponent,
    loadChildren: () =>
      import('src/app/modules/games/games.module').then(m => m.GamesModule),
    ...canActivate(authenticatedUsers),
  },
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () =>
      import('src/app/modules/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
    ...canActivate(authenticatedUsers),
  },
  // Not found
  { path: '**', redirectTo: '' },
]
