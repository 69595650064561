import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TwitchUser } from 'src/app/models/twitch-user.model'
import { AuthService } from '../auth.service'

export const BASE_URL = 'https://api.twitch.tv/helix'
@Injectable({ providedIn: 'root' })
export class ApiTwitchService {
  constructor(private auth: AuthService, private httpClient: HttpClient) {
    console.log('ApiTwitchService constructor...')
  }

  async getMe() {
    const usersResult = await this.httpClient
      .get<any>(`${BASE_URL}/users`)
      .toPromise()

    return usersResult.data[0]
  }

  async getUsers(usersIds: string[]): Promise<TwitchUser[]> {
    let start = 0
    let pendingUsersIds = []
    let users: TwitchUser[] = []

    do {
      let params = new HttpParams()
      pendingUsersIds = usersIds.slice(start, start + 100).map((id: string) => {
        params = params.append('id', id)
      })

      if (pendingUsersIds.length > 0) {
        const usersResult = await this.httpClient
          .get<any>(`${BASE_URL}/users`, {
            params,
          })
          .toPromise()

        if (usersResult) {
          users.push(
            ...usersResult.data.map((u: any) => ({
              id: u.id,
              login: u.login,
              displayName: u.display_name,
              profileImageUrl: u.profile_image_url,
            }))
          )
        }
      }

      start = start + 100
    } while (pendingUsersIds.length > 0)

    return users
  }

  async fetchSubscribersCount(
    broadcaster_id: string,
    after?: string
  ): Promise<number> {
    const subscribersResult = await this.httpClient
      .get<any>('https://api.twitch.tv/helix/subscriptions', {
        params: {
          broadcaster_id,
          first: 1,
          after: after || '',
        },
      })
      .toPromise()

    return subscribersResult.total
  }

  async fetchSubscribers(
    broadcaster_id: string,
    after?: string
  ): Promise<any[]> {
    const subscribersResult = await this.httpClient
      .get<any>('https://api.twitch.tv/helix/subscriptions', {
        params: {
          broadcaster_id,
          first: 100,
          after: after || '',
        },
      })
      .toPromise()

    if (subscribersResult.pagination.cursor) {
      return [
        ...subscribersResult.data,
        ...(await this.fetchSubscribers(
          broadcaster_id,
          subscribersResult.pagination.cursor
        )),
      ]
    } else {
      return subscribersResult.data
    }
  }

  async getBitsLeaderboard(
    count: number,
    period: 'day' | 'week' | 'month' | 'year' | 'all'
  ) {
    const date = new Date()

    const result = await this.httpClient
      .get<any>(`${BASE_URL}/bits/leaderboard`, {
        params: {
          count,
          period,
          started_at: date.toISOString(),
        },
      })
      .toPromise()

    return result.data
  }
}
