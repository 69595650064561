export enum ToastType {
  SUCCESS,
  ERROR,
  INFO,
  WARNING,
}

export class Toast {
  type!: ToastType
  message!: string
  payload?: any
}
