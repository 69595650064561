import { Overlay } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AuthButtonComponent } from './auth-button/auth-buton.component'
import { ToastsComponent } from './toasts/toasts.component'
import { TooltipDirective } from './tooltip/tooltip.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [AuthButtonComponent, ToastsComponent, TooltipDirective],
  exports: [AuthButtonComponent, ToastsComponent, TooltipDirective],
})
export class AppComponentsModule {}
