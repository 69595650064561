import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router, RouterModule } from '@angular/router'
import { AppComponentsModule } from '../components/app-components.module'
import { CoreComponent } from './core-component'
import { LayoutComponent } from './layout/layout.component'
import { SharedModule } from './shared.module'
import { SidebarComponent } from './sidebar/sidebar.component'
import { SignInComponent } from './sign-in/sign-in.component'

import * as Sentry from '@sentry/angular'
import { ToastsService } from '../components/toasts/toasts.service'
import { appRoutes } from '../routes'
import { ApiTwitchInterceptor } from '../services/api-twitch/api-twitch.interceptor'
import { ApiTwitchService } from '../services/api-twitch/api-twitch.service'
import { AuthService } from '../services/auth.service'
import { SidebarService } from './sidebar/sidebar.service'

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    BrowserAnimationsModule,
    AppComponentsModule,
  ],
  declarations: [
    CoreComponent,
    SignInComponent,
    LayoutComponent,
    SidebarComponent,
  ],
  providers: [
    AuthService,
    ApiTwitchService,
    ToastsService,
    SidebarService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiTwitchInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [CoreComponent],
})
export class CoreModule {}
