export const environment = {
  production: true,
  twitch: {
    apiBaseURL: 'https://api.twitch.tv/helix',
    clientId: '0h2fpqxsbrbg9ydeo7nb9xayosf3h2',
    scope: 'user:read:email',
  },
  firebase: {
    apiKey: 'AIzaSyBVl_UXES83uv7kc3ZvNZsGHy391234itk',
    authDomain: 'streamclub-app.firebaseapp.com',
    projectId: 'streamclub-app',
    storageBucket: 'streamclub-app.appspot.com',
    messagingSenderId: '73935204393',
    appId: '1:73935204393:web:b46f5990492c40b597ac38',
    measurementId: 'G-Q7HP2VFQ1Z',
  },
  apiBaseURL: 'https://api.streamclub.app',
  sentry: {
    dsn: 'https://18f1b6e09fd14431b617ac0891d9aa0e@o407090.ingest.sentry.io/5889770',
    tracingOrigins: ['streamclub.app', 'api.streamclub.app'],
  },
}
